import React from 'react'
import { graphql } from 'gatsby'
import Container from '../components/Container'
import GraphQLErrorList from '../components/GraphQLErrorList'
import BlogPost from '../components/BlogPost'
import SEO from '../components/SEO'
import LayoutContainer from '../containers/LayoutContainer'
import { toPlainText } from '../lib/helpers'

export const query = graphql`
  query BlogPostTemplateQuery($id: String!) {
    post: sanityPost(id: { eq: $id }) {
      id
      publishedAt
      isKorean
      hiddenFromList
      otherLangVersion
      categories {
        _id
        title
      }
      title
      slug {
        current
      }
      series {
        _key
        _type
        series {
          id
          title
          slug
        }
      }
      _rawExcerpt(resolveReferences: { maxDepth: 5 })
      _rawBody(resolveReferences: { maxDepth: 5 })
      mainImage {
        _key
        _type
        caption
        alt
        asset {
          _id
        }
        crop {
          _key
          _type
          top
          bottom
          left
          right
        }
        hotspot {
          _key
          _type
          x
          y
          height
          width
        }
      }
      metaImage {
        _key
        _type
        asset {
          _id
        }
      }
    }
  }
`

const BlogPostTemplate = props => {
  const { data, errors } = props
  const { post } = data || {}
  const { isKorean, series } = post
  let backUrl
  if (series) {
    backUrl = `/${series.series.slug}`
  } else {
    backUrl = isKorean ? '/posts/ko' : '/posts'
  }
  return (
    <LayoutContainer>
      {errors && <SEO title="GraphQL Error" />}
      {post && (
        <SEO
          title={post.title || 'Untitled'}
          lang={post.isKorean ? 'ko' : 'en'}
          description={toPlainText(post._rawExcerpt)}
          image={post.metaImage || post.mainImage}
        />
      )}

      {errors && (
        <Container>
          <GraphQLErrorList errors={errors} />
        </Container>
      )}

      {post && <BlogPost backUrl={backUrl} {...post} />}
    </LayoutContainer>
  )
}

export default BlogPostTemplate
