/** @jsx jsx */
import { jsx } from 'theme-ui'
import { Link } from 'gatsby'
import { format, distanceInWords, differenceInDays } from 'date-fns'
import PortableText from './PortableText'
import Container from './Container'
import Figure from './Figure'
import Hello from './Hello'

function BackButton({ className, backUrl, mainImage }) {
  return (
    <Link
      className={className}
      to={backUrl}
      sx={{
        p: 6,
        display: 'inline-block',
        color: 'black',
        backgroundColor: mainImage ? 'white' : 'transparent',
        lineHeight: '11px',
        opacity: 0.6,
        '&:hover': {
          opacity: 0.8,
        },
      }}
    >
      <svg style={{ width: '24px', height: '24px' }} viewBox="0 0 24 24">
        <path
          fill="currentColor"
          d="M20,11V13H8L13.5,18.5L12.08,19.92L4.16,12L12.08,4.08L13.5,5.5L8,11H20Z"
        />
      </svg>
      <span hidden>Go Back</span>
    </Link>
  )
}

function BlogPost({
  _rawBody,
  backUrl,
  title,
  publishedAt,
  isKorean,
  otherLangVersion,
  mainImage,
  hiddenFromList,
}) {
  return (
    <article>
      {mainImage && (
        <div>
          <Figure
            node={mainImage}
            sx={{
              m: 0,
              figcaption: {
                display: 'none',
              },
            }}
          />
          {!hiddenFromList && (
            <BackButton
              sx={{ p: 2, position: 'absolute', top: 4, left: 4, borderRadius: 'base' }}
              backUrl={backUrl}
              mainImage={mainImage}
            />
          )}
        </div>
      )}
      <Container sx={{ p: 0, position: 'relative' }}>
        <div>
          {!mainImage && !hiddenFromList && <BackButton backUrl={backUrl} mainImage={mainImage} />}
          <h1
            sx={{
              fontSize: '4xl',
              color: 'black',
              mt: mainImage ? 8 : 2,
              mb: 4,
              mx: 6,
              opacity: 0.9,
              lineHeight: 'tight',
            }}
          >
            {title}
          </h1>
          {publishedAt && (
            <div
              sx={{
                fontSize: 'sm',
                mx: 6,
                color: 'black',
                opacity: 0.8,
              }}
            >
              {differenceInDays(new Date(publishedAt), new Date()) > 3
                ? distanceInWords(new Date(publishedAt), new Date())
                : format(new Date(publishedAt), 'MMMM Do, YYYY')}
            </div>
          )}
          {otherLangVersion && (
            <p
              sx={{
                m: 6,
                py: 2,
                px: 4,
                borderLeftColor: 'blue',
                borderLeftWidth: '4px',
                borderLeftStyle: 'solid',
                '& a': {
                  color: 'blue',
                  textDecoration: 'none',
                },
                '& a:hover': {
                  textDecoration: 'underline',
                },
              }}
            >
              <Link to={`/post/${otherLangVersion}`}>
                {isKorean ? 'Read the English version →' : '한글 버전 읽기 →'}
              </Link>
            </p>
          )}
          {_rawBody && (
            <PortableText sx={{ mt: 2 }} isKorean={isKorean} blocks={_rawBody} insidePost />
          )}
          <hr
            sx={{
              borderTop: 0,
              borderBottom: 'lightBlack',
              mx: 6,
              mb: 12,
            }}
          />
          <Hello
            sx={{
              mx: 6,
              '& h1': {
                textAlign: 'left',
              },
              '& div': {
                alignItems: 'flex-start',
              },
            }}
          />
        </div>
      </Container>
    </article>
  )
}

export default BlogPost
